<template>
    <div class="space-y-4">
      <offline-action-alert v-if="!isOnline" />
      <asom-alert v-if="error" variant="error" :error-message="error" />
      <article>
        <asom-card>
          <form class="grid sm:grid-cols-1 md:grid-cols-4 gap-4">
            <asom-form-field
              :label="'Inventory Group'"
              :state="inputStates('formData.inventoryGroup')"
              error="Inventory Group is required"
            >
              <asom-input-select
                :state="inputStates('formData.inventoryGroup')"
                v-model="formData.inventoryGroup"
                :options="inventoryGroups"
              />
            </asom-form-field>
            <asom-form-field
              :label="'Item Type'"
              required
              :state="inputStates('formData.inventoryType')"
              error="Item Type is required"
            >
              <asom-input-select
                :state="inputStates('formData.inventoryType')"
                v-model="formData.inventoryType"
                :loading="isLoadingInventoryTypes"
                :options="inventoryTypeOptions"
                @search-change="queryInventoryTypes"
                ref="itemTypeSelection"
                placeholder="Type to search for Item Type"
              />
            </asom-form-field>
            <asom-form-field
              class="col-span-2"
              label="Threshold Quantity"
              required
              :state="inputStates('formData.quantity')"
              error="Threshold Quantity is required"
            >
              <asom-input-text
                type="number"
                v-model="formData.quantity"
                :state="inputStates('formData.quantity')"
              />
            </asom-form-field>
            <div class="col-span-4 flex justify-end space-x-4 pt-8">
              <asom-button
                text="Back"
                variant="secondary"
                @click="$router.go(-1)"
              />
              <asom-button
                text="Create"
                @click="createinventoryTypeThresholdClicked"
                :loading="isSubmitting"
              />
            </div>
          </form>
        </asom-card>
      </article>
    </div>
  </template>
  
  <script>
  import get from "lodash.get";
  import { mapGetters } from "vuex";
  import { required, minValue, maxValue } from "@vuelidate/validators";
  import inputStates from "@/mixins/inputStates";
  import { InventoryGroups } from "../../../../constants/APIEnums/inventoryEnums";
  import { useVuelidate } from "@vuelidate/core";
  import { getListOfInventoryTypes, createInventoryTypeThreshold } from "../../../../services/inventory.service";
  
  export default {
    setup: () => ({ v$: useVuelidate() }),
    mixins: [inputStates],
    data() {
      return {
        error: null,
        isSubmitting: false,
        formData: {
          inventoryGroup: null,
          inventoryTypeName: null,
          quantity: null,
          inventoryTypeId: null,
        },
        inventoryTypeOptions: [],
        isLoadingInventoryTypes: false,
      };
    },
    validations() {
      return {
        formData: {
            inventoryGroup: { required },
            inventoryType: { required },
            quantity: { 
                required, 
                minValue: minValue(1), 
                maxValue: maxValue(100000) 
            },
        },
      };
    },
    computed: {
      ...mapGetters({
        lineId: "selectedStation/lineId",
        stationId: "selectedStation/id",
        stationName: "selectedStation/name",
        isOnline: "apiStatus/isInventoryModuleOnline",
      }),
      inventoryGroups() {
        return [
          {
            label: InventoryGroups.GENERAL.NAME,
            value: InventoryGroups.GENERAL.VALUE,
          },
          {
            label: InventoryGroups.SMART_CARD.NAME,
            value: InventoryGroups.SMART_CARD.VALUE,
          },
          {
            label: InventoryGroups.SERIALISED_TAG.NAME,
            value: InventoryGroups.SERIALISED_TAG.VALUE,
          },
          {
            label: InventoryGroups.FIRST_AID.NAME,
            value: InventoryGroups.FIRST_AID.VALUE,
          },
        ];
      },
    },
    watch: {
      "formData.inventoryGroup": function(newValue, prevValue) {
        if (get(prevValue, "value") !== get(newValue, "value")) {
            this.queryInventoryTypes("", true);
            this.formData.inventoryType = null;
        }
      }
    },
    methods: {
      async queryInventoryTypes(query, allowEmptyQuery = false) {
        this.modalError = null;
        this.isLoadingInventoryTypes = true;
        if (!query || !allowEmptyQuery) {
            this.isLoadingInventoryTypes = false;
            this.inventoryTypeOptions = [];
        }
        const result = await getListOfInventoryTypes({
            stationId: this.stationId,
            inventoryGroup: get(this.formData.inventoryGroup, "value", null),
            search: query.trim(),
        });
        if (result.success && Array.isArray(get(result, "payload.list"))) {
            this.inventoryTypes = get(result, "payload.list", []);
            this.isLoadingInventoryTypes = false;
            this.modalError = null;
            this.inventoryTypeOptions = get(result, "payload.list").map(
            ({ inventoryTypeId, inventoryTypeName }) => ({
                label: inventoryTypeName,
                value: inventoryTypeId,
            })
            );
        } else {
            this.isLoadingInventoryTypes = false;
            this.modalError = result.payload;
            this.inventoryTypeOptions = [];
        }
        },
      createinventoryTypeThresholdClicked() {
        if (this.isSubmitting) return;
        this.error = "";
        this.v$.$reset();
        this.v$.$touch();
        if (!this.v$.$invalid) {
          this.onSubmit();
        } else {
          this.error = "Please complete all required fields";
          this.$scrollTop();
        }
      },
      async onSubmit() {
        this.isSubmitting = true;
        let data = {
            lineId: this.lineId,
            stationId: this.stationId,
            inventoryTypeId: get(this.formData.inventoryType, "value"),
            quantity: this.formData.quantity
        };
        const result = await createInventoryTypeThreshold(data);
        if (result.success) {
            this.isSubmitting = false;
            this.$router.go(-1);
        } else {
            this.isSubmitting = false;
            this.error = result.payload;
            this.$scrollTop();
        }
      },
    },
  };
  </script>
  